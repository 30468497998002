import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EquipmentRoutingModule} from './equipment-routing.module';
import {EquipmentBoardComponent} from './equipment-board/equipment-board.component';
import {EquipmentDetailsComponent} from './equipment-details/equipment-details.component';
import {HierarchyModule} from "../hierarchy/hierarchy.module";
import {SiteModule} from "../site/site.module";
import {SystemModule} from "../system/system.module";
import {TranslateModule} from "@ngx-translate/core";
import {EquipmentImageComponent} from './equipment-image/equipment-image.component';
import {
  EquipmentDetailsTabsComponent
} from './equipment-details-tabs/equipment-details-tabs.component';
import {MaterialModule} from "../material/material.module";
import {
  EquipmentDetailsStatusComponent
} from './equipment-details-status/equipment-details-status.component';
import {EquipmentEnvelopeComponent} from './equipment-envelope/equipment-envelope.component';
import {NgxEchartsDirective} from "ngx-echarts";
import {
  EquipmentDetailsAlarmsComponent
} from './equipment-details-alarms/equipment-details-alarms.component';
import {
  EquipmentDetailsModuleSettingsComponent
} from './equipment-details-module-settings/equipment-details-module-settings.component';
import {
  EquipmentDetailsInformationComponent
} from './equipment-details-information/equipment-details-information.component';
import {
  EquipmentDetailsDocumentComponent
} from './equipment-details-document/equipment-details-document.component';
import {ComponentModule} from "../component/component.module";
import {AlarmModule} from "../alarm/alarm.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  EquipmentActivationStatusComponent
} from './equipment-activation-status/equipment-activation-status.component';
import {ActivationModule} from "../activation/activation.module";
import {
  SparePartImageDialogComponent
} from './spare-part-image-dialog/spare-part-image-dialog.component';
import {
  EquipmentDetailsAlarmDialogComponent
} from './equipment-details-alarm-dialog/equipment-details-alarm-dialog.component';
import {
  ClearAlarmConfirmationDialogComponent
} from './clear-alarm-confirmation-dialog/clear-alarm-confirmation-dialog.component';
import {FilterModule} from "../filter/filter.module";
import {HeaderToolbarModule} from "../component/header-toolbar/header-toolbar.module";
import {
  DeleteEquipmentDialogComponent
} from './delete-equipment-dialog/delete-equipment-dialog.component';
import {
  ChangeEquipmentDialogComponent
} from './change-equipment-dialog/change-equipment-dialog.component';
import {
  EquipmentActivationMenuComponent
} from './equipment-activation-menu/equipment-activation-menu.component';
import {
  EquipmentDetailsSparePartsComponent
} from "./equipment-details-spare-parts/equipment-details-spare-parts.component";
import {
  EquipmentDetailsInformationMainComponent
} from "./equipment-details-information-main/equipment-details-information-main.component";
import {OperationalGraphModule} from "./operational-graph/operational-graph.module";
import {SparePartsModule} from "../portal/spare-parts/spare-parts.module";
import {ProductModule} from "../portal/product/product.module";
import {DocumentModule} from "../portal/document/document.module";
import {OperationalReportModule} from "./operational-report/operational-report.module";


@NgModule({
  declarations: [
    EquipmentBoardComponent,
    EquipmentDetailsComponent,
    EquipmentImageComponent,
    EquipmentDetailsTabsComponent,
    EquipmentDetailsStatusComponent,
    EquipmentEnvelopeComponent,
    EquipmentDetailsAlarmsComponent,
    EquipmentDetailsModuleSettingsComponent,
    EquipmentDetailsInformationComponent,
    EquipmentDetailsDocumentComponent,
    EquipmentActivationStatusComponent,
    EquipmentDetailsSparePartsComponent,
    SparePartImageDialogComponent,
    EquipmentDetailsAlarmDialogComponent,
    ClearAlarmConfirmationDialogComponent,
    DeleteEquipmentDialogComponent,
    ChangeEquipmentDialogComponent,
    EquipmentActivationMenuComponent,
    EquipmentDetailsInformationMainComponent
  ],
  exports: [
    EquipmentImageComponent
  ],
  imports: [
    CommonModule,
    EquipmentRoutingModule,
    HierarchyModule,
    SiteModule,
    SystemModule,
    ActivationModule,
    TranslateModule,
    MaterialModule,
    NgxEchartsDirective,
    ComponentModule,
    AlarmModule,
    ReactiveFormsModule,
    FormsModule,
    FilterModule,
    HeaderToolbarModule,
    SparePartsModule,
    ProductModule,
    OperationalGraphModule,
    OperationalReportModule,
    DocumentModule
  ]
})
export class EquipmentModule {
}
