import { Component, Input } from '@angular/core';
import { AlarmInfo } from "../../../../../alarm/model/alarm-api";
import { AlarmDistributionLevel } from "../../../model/operational-report-alarms-api";
import { MatDialog } from "@angular/material/dialog";
import { AlarmInfoDialogComponent } from "../../../../../alarm/alarm-info-dialog/alarm-info-dialog.component";

@Component({
    selector: 'app-distribution-entry',
    templateUrl: './distribution-entry.component.html',
    styleUrl: './distribution-entry.component.scss',
    standalone: false
})
export class DistributionEntryComponent {

  @Input()
  set entriesData(data: AlarmInfo[]) {
    this.entries = data
    this.data = []
    this.mapData()
  }

  entries: AlarmInfo[] = []
  data: AlarmDistributionLevel[] = []

  constructor(
    private dialog: MatDialog
  ) { }

  openAlarmsDialog(code: number) {
    const filtered = this.entries.filter(e => e.alarm.metadata.code === code)
    if (!filtered.length) return

    const dialogRef = this.dialog.open(AlarmInfoDialogComponent, {
      width: '85%',
      data: filtered
    })
  }

  private mapData() {
    if (!this.entries.length) return

    this.entries.forEach(e => {
      const alarm = new AlarmDistributionLevel(e.alarm.metadata.code, e.alarm.metadata.level, 1)
      const idx = this.data.findIndex(d => d.code === alarm.code)
      if (idx === -1) {
        this.data.push(alarm)
      } else {
        this.data[idx].amount++
      }
    })
  }
}
