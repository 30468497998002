import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { KnowledgeService } from "../../registration/model/knowledge.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Equipment, EquipmentUpdateRequest } from "../model/equipment-api";
import { EquipmentService } from "../model/equipment.service";
import { ModuleService } from "../../module/model/module.service";
import { ElectronicModule, ElectronicModuleValidationRequest } from "../../module/model/module-api";

@Component({
    selector: 'app-change-equipment-dialog',
    templateUrl: './change-equipment-dialog.component.html',
    styleUrls: ['./change-equipment-dialog.component.scss'],
    standalone: false
})
export class ChangeEquipmentDialogComponent {

  equipmentData: FormGroup | undefined
  filteredTypes: Observable<string[]> | undefined
  refrigerants: string[] = []
  electronicModules: string[] = []
  private subject: Subject<string> = new Subject();
  private serialNumberChange: Subject<string> = new Subject()
  loading = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Equipment,
    private fb: FormBuilder,
    private service: EquipmentService,
    private moduleService: ModuleService,
    private knowledgeService: KnowledgeService,
    private dialogRef: MatDialogRef<ChangeEquipmentDialogComponent>
  ) {
    this.serialNumberChange.pipe(
      debounceTime(1000)
    ).subscribe(serialNumber => this.serialNumberValidation(serialNumber))
    this.knowledgeService.subject.subscribe(data => this.refrigerants = data)
  }

  ngOnInit(): void {
    if (!this.data) return
    this.moduleService.findByEquipmentId(this.data.id).subscribe(modules => {
      this.handleModule(modules.content)
    })
    this.refrigerants = this.knowledgeService.loadRefrigerants()
    this.electronicModules = this.knowledgeService.getElectronicModules()
    this.equipmentData = this.fb.group({
      name: this.fb.control(this.data?.name, [Validators.required]),
      refrigerant: this.fb.control(this.data?.refrigerant, [Validators.required]),
      serialnumber: this.fb.control(this.data?.serialNumber, [Validators.required]),
      type: this.fb.control(this.data?.productId, [Validators.required]),
      electronicModuleType: this.fb.control(''),
      electronicModuleSN: this.fb.control(''),
    })

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(searchTextValue => {
      this.loading = true
      this.filteredTypes = this.knowledgeService.getTypes(searchTextValue)
      this.filteredTypes.subscribe(result => {
        this.loading = false
        if (!result.includes(searchTextValue)) {
          this.equipmentData?.get('type')?.setErrors({ incorrectType: { value: true } })
        } else {
          this.equipmentData?.get('type')?.setErrors(null)
        }
      })
    });
  }

  private handleModule(content: ElectronicModule[]) {
    if (!content || !content.length) return
    let eModule = content[0]
    this.equipmentData?.get('electronicModuleType')?.setValue(eModule.type)
    this.equipmentData?.get('electronicModuleSN')?.setValue(eModule.serialNumber)

  }


  update() {
    if (this.equipmentData?.invalid) return
    let values = this.equipmentData?.value

    let request = new EquipmentUpdateRequest(
      values.name ?? "",
      values.refrigerant ?? "",
      values.type ?? "",
      values.serialnumber ?? "",
      values.electronicModuleType ?? "",
      values.electronicModuleSN ?? ""
    )
    if (request) {
      this.service.changeEquipment(this.data.id, request).subscribe(it => {
        if (!it) return
        this.dialogRef.close(true)
      })
    }
  }

  onKeyUp(searchTextValue: string) {
    this.subject.next(searchTextValue);
  }

  onSerialNumberChange(newValue: string) {
    this.serialNumberChange.next(newValue)
  }

  onModuleTypeChange(moduleType: string) {
    const moduleTypes = ['CM-RC-01', 'CM-SW-01', 'SE-i1', 'CM-RC-02']
    const serialNumber = this.equipmentData?.get('serialnumber')?.value
    const electronicSerialNumber = this.equipmentData?.get('electronicModuleSN')

    if (electronicSerialNumber?.value && electronicSerialNumber?.value !== serialNumber) return

    if (moduleTypes.includes(moduleType)) {
      electronicSerialNumber?.setValue('')
      return
    }

    electronicSerialNumber?.setValue(serialNumber)
  }

  findType() {
    const serialNumber = this.equipmentData?.get('serialnumber')?.value || ''
    this.knowledgeService.getTypeBySerialNumber(serialNumber).subscribe((data: any) => {
      this.equipmentData?.get('type')?.setValue(data.value)
    })
  }

  private serialNumberValidation(serialNumber: string) {
    const moduleType = this.equipmentData?.get('electronicModuleType')?.value
    if (!moduleType) {
      this.handleSerialNumberValidationResult(true)
    } else {
      this.moduleService.validate(new ElectronicModuleValidationRequest(moduleType, serialNumber)).subscribe({
        next: value => this.handleSerialNumberValidationResult(value),
        error: err => this.handleSerialNumberValidationResult(false)
      })
    }
  }

  private handleSerialNumberValidationResult(result: boolean) {
    if (!this.equipmentData) return
    if (result) {
      this.equipmentData.setErrors(null)
    } else {
      this.equipmentData.get('electronicModuleSN')?.setErrors({ incorrectSerialNumber: { value: true } })
    }
  }
}
