import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Equipment } from "../model/equipment-api";
import { AlarmService } from "../../alarm/model/alarm.service";
import { AlarmInfo } from "../../alarm/model/alarm-api";
import { PageEvent } from "@angular/material/paginator";
import { BestCoreGatewayService } from "../model/best-core-gateway.service";
import { Page } from "../../utils/page";
import { TranslateService } from "@ngx-translate/core";
import { EquipmentService } from "../model/equipment.service";
import { ClearAlarmConfirmationDialogComponent } from "../clear-alarm-confirmation-dialog/clear-alarm-confirmation-dialog.component";

@Component({
    selector: 'app-equipment-details-alarm-dialog',
    templateUrl: './equipment-details-alarm-dialog.component.html',
    styleUrls: ['./equipment-details-alarm-dialog.component.scss'],
    standalone: false
})
export class EquipmentDetailsAlarmDialogComponent {

  reloading: boolean = false
  data: Page<AlarmInfo> | undefined
  pageSize = 10
  pageIndex: number = 0

  constructor(
    @Inject(MAT_DIALOG_DATA) public equipment: Equipment,
    public dialogRef: MatDialogRef<EquipmentDetailsAlarmDialogComponent>,
    public service: EquipmentService,
    private alarmService: AlarmService,
    private bestService: BestCoreGatewayService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.loadAlarms(this.pageIndex, this.pageSize)
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loadAlarms(event.pageIndex, event.pageSize)
  }


  private loadAlarms(page: number, size: number) {
    this.alarmService.getActiveAlarms(this.equipment.id, page, size, this.translate.currentLang).subscribe(d => this.handleData(d))
  }

  private handleData(data: Page<AlarmInfo>) {
    this.data = data
    this.pageIndex = data.pageable.number
    this.pageSize = data.pageable.size
    this.reloading = false
  }


  clearAlarms() {
    if (!this.service.hasMaintenancePermissions()) return

    const dialogRef = this.dialog.open(ClearAlarmConfirmationDialogComponent)
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return
      this.bestService.clearAlarms(this.equipment.id, this.equipment.thingId).subscribe(it => {
        this.loadAlarms(this.pageIndex, this.pageSize)
      })
    });


  }
}
