import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { OperationalGraphService } from "../model/operational-graph.service";
import { PropertyCategory, PropertyDefinition } from "../../../thing-template/model/thing-template-api";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { FormControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
    selector: 'app-operation-graph-property-selector',
    templateUrl: './operation-graph-property-selector.component.html',
    styleUrl: './operation-graph-property-selector.component.scss',
    standalone: false
})
export class OperationGraphPropertySelectorComponent {

  search = new FormControl('')
  categories: PropertyCategory[] = []
  properties: PropertyDefinition[] = []
  filteredProperties: PropertyDefinition[] = []
  selectedCategories: PropertyCategory[] = []
  selectedProperties: PropertyDefinition[] = []
  isSmallScreen: boolean = false
  weatherSeries: string[] = []

  constructor(
    public dialogRef: MatDialogRef<OperationGraphPropertySelectorComponent>,
    private service: OperationalGraphService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.categories = this.buildCategories()
    this.properties = this.buildProperties()
    this.selectedProperties = [...this.service.selectedProperties]
    this.weatherSeries = [...this.service.weatherSeries]
    this.updateSelectedCategories()
    this.search.valueChanges.subscribe(value => this.filterProperties(value || ''))
    this.handleScreenSizeChange()
  }

  private buildCategories(): PropertyCategory[] {
    // flatten all categories of the templates from the OperationalGraphService
    const categories: PropertyCategory[] = []
    return categories.concat(...this.service.templates.map(t => t.categories))
  }

  private buildProperties(): PropertyDefinition[] {
    const properties: PropertyDefinition[] = []
    return properties.concat(...this.categories.map(c => c.properties))
  }

  private filterProperties(value: string) {
    const filterValue = value.toLowerCase()
    this.filteredProperties = this.properties.filter(p => p.name.toLowerCase().includes(filterValue))
  }

  selectCategory(category: PropertyCategory) {
    // add all the properties from the category to the selected properties
    if (this.selectedCategories.includes(category)) {
      this.removeCategory(category)
      return
    }
    this.selectedCategories.push(category)
    const propertiesToAdd = category.properties.filter(p => !this.selectedProperties.includes(p))
    this.selectedProperties = this.selectedProperties.concat(...propertiesToAdd)
  }

  handlePropertySelectionChanged(event: MatSlideToggleChange, property: PropertyDefinition) {
    // add the property definition to the selected properties array
    if (event.checked) {
      this.selectedProperties.push(property)
    } else {
      this.selectedProperties = this.selectedProperties.filter(p => p !== property)
    }
    this.updateSelectedCategories()
  }

  onCancelClick(): void {
    this.dialogRef.close()
  }

  onSaveClick() {
    this.service.setWeatherSeries(this.weatherSeries)
    this.dialogRef.close(this.selectedProperties)
  }

  trimPropertyName(name: string) {
    return this.service.trimPropertyName(name)
  }

  getCheckboxState(category: PropertyCategory) {
    const areAllSelected = this.selectedCategories.indexOf(category) !== -1
    const arePartiallySelected = category.properties.some(p => this.selectedProperties.includes(p))

    return areAllSelected || arePartiallySelected
  }

  getCheckboxColor(category: PropertyCategory): string {
    return this.selectedCategories.indexOf(category) !== -1 ? 'accent' : 'primary'
  }

  private updateSelectedCategories() {
    // add all categories to the selected categories where all the properties are selected
    this.selectedCategories = this.categories.filter(c =>
      c.properties.every(p =>
        this.selectedProperties.includes(p)
      )
    )
  }

  private removeCategory(category: PropertyCategory) {
    const idx = this.selectedCategories.findIndex(c => c.id === category.id)
    this.selectedCategories.splice(idx, 1)
    this.selectedProperties = this.selectedProperties.filter(p => !category.properties.includes(p))
  }

  private handleScreenSizeChange() {
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
      this.isSmallScreen = result.matches
    })
  }

  setWeatherSeries(event: any, series: string) {
    if (series === 'all') {
      if (event.checked) {
        this.weatherSeries = ['temp', 'humidity', 'pressure']
      } else {
        this.weatherSeries = []
      }
      return
    }
    if (event.checked) this.weatherSeries.push(series)
    else this.removeSeries(series)
  }

  removeSeries(series: string){
    this.weatherSeries.forEach((item, index) => {
      if(item === series) this.weatherSeries.splice(index, 1)
    })
  }

}
