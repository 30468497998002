import { AfterViewInit, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import { AlarmInfo } from "../model/alarm-api";
import { AlarmModule } from "../alarm.module";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { AlarmHintsDialogComponent } from "../alarm-hints-dialog/alarm-hints-dialog.component";
import { SystemTimeService } from "../../system/system-time/system-time.service";

@Component({
    selector: 'app-alarm-info-dialog',
    imports: [
        AlarmModule,
        MatButtonModule,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        MatDialogTitle,
        TranslateModule,
        DatePipe,
        MatTableModule
    ],
    templateUrl: './alarm-info-dialog.component.html',
    styleUrl: './alarm-info-dialog.component.scss'
})
export class AlarmInfoDialogComponent implements AfterViewInit {

  displayedColumns = ['date', 'level', 'code', 'description']
  dataSource: MatTableDataSource<AlarmInfo> = new MatTableDataSource()
  alarms: AlarmInfo[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AlarmInfo[],
    private systemTime: SystemTimeService,
    private dialog: MatDialog
  ) {
  }

  ngAfterViewInit() {
    this.convertToUserTime()
  }

  openHintsDialog(alarm: AlarmInfo) {
    const dialogRef = this.dialog.open(AlarmHintsDialogComponent, {
      width: '85%',
      data: alarm
    })
  }

  private convertToUserTime() {
    this.alarms  = this.data.map(info => JSON.parse(JSON.stringify(info)))
    this.alarms.forEach(info => info.alarm.timestamp = this.systemTime.formatTimestamp(info.alarm.timestamp))
  }
}
