import { Component, Input } from '@angular/core';
import { EquipmentStartsDistribution } from "../../model/operational-report-starts-api";
import { BarChartEntry } from "../../operational-bar-chart/model/bar-chart.api";

@Component({
    selector: 'app-starts-distribution',
    templateUrl: './starts-distribution.component.html',
    styleUrl: './starts-distribution.component.scss',
    standalone: false
})
export class StartsDistributionComponent {

  @Input()
  set distributionData(data: EquipmentStartsDistribution | undefined) {
    this.distribution = data
    this.init()
  }

  distribution: EquipmentStartsDistribution | undefined
  entry: BarChartEntry | undefined

  constructor() { }

  private init() {
    if (!this.distribution) return

    const sorted = this.distribution.entry.sort((a, b) => b.interval.start - a.interval.start)
    const series = sorted.map(d => Number(d.percentage.toFixed(2)))
    const yAxisData = this.getYAxisValues()
    const formatter = (value: string) => `${value} starts/hour`

    this.entry = new BarChartEntry(yAxisData, formatter, series)
  }

  private getYAxisValues() {
    return [
      '> 8',
      '5 - 8',
      '2 - 4',
      '<= 1'
    ]
  }
}
