import { Component, Input } from '@angular/core';
import { AlarmInfo } from "../../../../alarm/model/alarm-api";
import { EquipmentLastAlarms } from "../../model/operational-report-alarms-api";
import { AlarmHintsDialogComponent } from "../../../../alarm/alarm-hints-dialog/alarm-hints-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AlarmType } from "../../../../site/model/site-api";
import { SystemTimeService } from "../../../../system/system-time/system-time.service";

@Component({
    selector: 'app-last-alarms',
    templateUrl: './last-alarms.component.html',
    styleUrl: './last-alarms.component.scss',
    standalone: false
})
export class LastAlarmsComponent {

  @Input()
  set alarmData(data: EquipmentLastAlarms | undefined) {
    if (!data) return
    this.alarms = data.alarms
    this.convertTimestamp()
  }

  alarms: AlarmInfo[] = []
  displayedColumns = ['date', 'code', 'description', 'level']

  constructor(
    private systemTime: SystemTimeService,
    private dialog: MatDialog
  ) { }

  openHintsDialog(alarm: AlarmInfo) {
    const dialogRef = this.dialog.open(AlarmHintsDialogComponent, {
      width: '85%',
      data: alarm
    })
  }

  defineStyle(info: AlarmInfo) {
    if (info.alarm.metadata.level === AlarmType.FAULT) {
      return { 'color': '#b00000' }
    } else if (info.alarm.metadata.level === AlarmType.CRITICAL) {
      return { 'color': '#e78c07' }
    }

    return { 'color': 'black' }
  }

  private convertTimestamp() {
    this.alarms.forEach(info => info.alarm.timestamp = this.systemTime.formatTimestamp(info.alarm.timestamp))
  }
}
