import { Component } from '@angular/core';

@Component({
    selector: 'app-equipment-board',
    templateUrl: './equipment-board.component.html',
    styleUrls: ['./equipment-board.component.scss'],
    standalone: false
})
export class EquipmentBoardComponent {

}
