import { Component, Inject } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Equipment } from "../../equipment/model/equipment-api";
import { BestCoreGatewayService } from "../../equipment/model/best-core-gateway.service";
import { BestInfo } from "../../equipment/model/best-core-api";
import { TranslateService } from "@ngx-translate/core";
import { ActivationEditDialogComponent } from "../activation-edit-dialog/activation-edit-dialog.component";
import { EquipmentService } from "../../equipment/model/equipment.service";
import { AccessService } from "../../access/model/access.service";

@Component({
    selector: 'app-activation-config-dialog',
    templateUrl: './activation-config-dialog.component.html',
    styleUrls: ['./activation-config-dialog.component.scss'],
    standalone: false
})
export class ActivationConfigDialogComponent {


  info = this.fb.group({
    gatewayStatus: this.fb.control(''),
    gatewayMAC: this.fb.control(''),
    firmwareVersion: this.fb.control(''),
  })
  device = this.fb.group({
    status: this.fb.control(''),
    protocol: this.fb.control(''),
    address: this.fb.control(''),
    errors: this.fb.control(''),
    type: this.fb.control(''),
  })
  loading = false
  canWrite = false


  constructor(
    @Inject(MAT_DIALOG_DATA) public equipment: Equipment,
    private fb: FormBuilder,
    private service: BestCoreGatewayService,
    public equipmentService: EquipmentService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private accessService: AccessService
  ) {
    this.loadData()
  }

  private loadData() {
    this.loading = true
    this.service.getInfo(this.equipment.id, this.equipment.thingId).subscribe(info => {
      this.handleData(info)
    })
    this.accessService.getEquipmentAccess(this.equipment.id).subscribe(access => {
      if(!access) return
      this.canWrite = access.canWrite
    })
  }

  private handleData(info: BestInfo) {
    this.loading = false
    if(!info) return
    let iotStatus = info.online ? 'ioTStatusText_Online' : 'ioTStatusText_Offline'
    this.info.get('gatewayStatus')?.setValue(this.translate.instant(iotStatus))
    this.info.get('gatewayMAC')?.setValue(info.macAddress)
    this.info.get('firmwareVersion')?.setValue(info.firmwareVersion)

    let deviceStatus = info.device.online ? 'ioTStatusText_Online' : 'ioTStatusText_Offline'
    let isLeProt = info.device.protocol.toLowerCase() === 'leprot'

    this.device.get('status')?.setValue(this.translate.instant(deviceStatus))
    this.device.get('protocol')?.setValue(info.device.protocol)
    this.device.get('address')?.setValue(isLeProt ? info.device.serialNumber : info.device.address)
    this.device.get('errors')?.setValue(info.device.commErrors.toString())
    this.device.get('type')?.setValue(info.device.type)
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(ActivationEditDialogComponent, {
      width: '50%',
      data: this.equipment
    })
    dialogRef.afterClosed().subscribe(d => this.loadData())
  }
}
