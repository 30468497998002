<h5 class="m-3">{{ 'CompRunningTime' | translate }}</h5>

<div class="flex justify-between">
  <div>
    <p class="m-3">{{ 'operatingHours' | translate }}
      : {{ report?.operatingHours?.toFixed(2) }} {{ 'hours' | translate }}</p>
  </div>
  @if (report && report.totalConsumption >= 0) {
    <div>
      <p class="m-3">{{ 'totalPower' | translate }}: {{ report.totalConsumption.toFixed(2) }}</p>
    </div>
  }
</div>

<div echarts
     [options]="initialValue"
     [merge]="dynamicData"
     style="height: 180px"
     (chartClick)="handleChartClick($event)"
     (chartInit)="setChart($event)"
></div>
