import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoggingService} from "../../utils/logging/logging.service";
import {BaseService} from "../../utils/base-service";
import {Observable} from "rxjs";
import {EnvelopeInfo, EnvelopePoint, EnvelopePropertyEntry, SiUnit} from "./property-api";
import {XAXisOption} from "echarts/types/dist/shared";
import {EChartsCoreOption} from "echarts/core";

@Injectable({
  providedIn: 'root'
})
export class EnvelopeService extends BaseService {

  private chartConfig: EChartsCoreOption = {
    xAxis: {
      name: 'To',
      nameLocation: 'middle',
      nameGap: 25,
      axisLine: {
        show: false
      },
      splitLine: {
        show: true
      },
      type: 'value',
      min: 0,
      max: 0
    },
    yAxis: {
      name: 'Tc',
      nameLocation: 'middle',
      nameGap: 25,
      axisLine: {
        show: false
      },
      splitLine: {
        show: true
      },
      type: 'value',
      min: 0,
      max: 0
    },
    series: []
  }

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/gateway/envelope', logging, zone)
  }

  getEnvelope(equipmentId: number): Observable<EnvelopeInfo> {
    return this.get<EnvelopeInfo>(equipmentId + '')
  }

  createChartOptions(info: EnvelopeInfo, properties: EnvelopePropertyEntry[]): EChartsCoreOption {
    let points = info.points

    const criticalData = this.parallelShiftPolygon(points, 2)
    const faultData = this.parallelShiftPolygon(points, 4)

    const warningData = this.parallelShiftPolygon(points, -2)
    const warningResetData = this.parallelShiftPolygon(points, -4)

    this.setAxisValues(faultData, info.type)

    // @ts-ignore
    this.chartConfig.series = [
      this.getLineSettings(points, 'black', 2),
      this.getLineSettings(faultData, 'red', 2, 'dashed'),
      this.getLineSettings(criticalData, 'orange', 1.5, 'dashed'),
      this.getLineSettings(warningData, 'green', 1, 'dashed'),
      this.getLineSettings(warningResetData, 'green', 1, 'dashed'),
      this.getPointSettings(properties)
    ]

    return this.chartConfig
  }

  resetAxisLabels() {
    // @ts-ignore
    this.chartConfig.xAxis.name = 'To'
    // @ts-ignore
    this.chartConfig.yAxis.name = 'Tc'
  }

  private parallelShiftPolygon(polygon: EnvelopePoint[], distance: number): EnvelopePoint[] {
    const shiftedPolygon: EnvelopePoint[] = []

    for (let i = 0; i < polygon.length; i++) {
      // calculate for each corner the two normal vectors and determine the intersection of them resulting lines
      const p1 = this.getPoint(polygon, i)
      const p2 = this.getPoint(polygon, i + 1)
      const p3 = this.getPoint(polygon, i + 2)
      const normalVectorP12 = this.calculateNormalVector(p1, p2)
      const normalVectorP23 = this.calculateNormalVector(p2, p3)

      const intersection = this.lineLineIntersection(
        {x: p1.x + normalVectorP12.x * distance, y: p1.y + normalVectorP12.y * distance},
        {x: p2.x + normalVectorP12.x * distance, y: p2.y + normalVectorP12.y * distance},
        {x: p2.x + normalVectorP23.x * distance, y: p2.y + normalVectorP23.y * distance},
        {x: p3.x + normalVectorP23.x * distance, y: p3.y + normalVectorP23.y * distance}
      )

      if (intersection) {
        shiftedPolygon.push(intersection)
      }
    }
    return shiftedPolygon
  }

  private getPoint(polygon: EnvelopePoint[], index: number): EnvelopePoint {
    if (index < polygon.length) return polygon[index]
    return polygon[index % polygon.length]
  }

  private calculateNormalVector(p1: EnvelopePoint, p2: EnvelopePoint): EnvelopePoint {
    const dx = p2.x - p1.x
    const dy = p2.y - p1.y
    const length = Math.sqrt(dx * dx + dy * dy)
    const normalX = -dy / length
    const normalY = dx / length
    return {x: normalX, y: normalY}
  }

  private lineLineIntersection(p1: EnvelopePoint, p2: EnvelopePoint, q1: EnvelopePoint, q2: EnvelopePoint): EnvelopePoint | null {
    const a1 = p2.y - p1.y;
    const b1 = p1.x - p2.x;
    const c1 = a1 * p1.x + b1 * p1.y;

    const a2 = q2.y - q1.y;
    const b2 = q1.x - q2.x;
    const c2 = a2 * q1.x + b2 * q1.y;

    const det = a1 * b2 - a2 * b1;

    if (det === 0) {
      // The lines are parallel and do not intersect.
      return null;
    }

    const x = (b2 * c1 - b1 * c2) / det;
    const y = (a1 * c2 - a2 * c1) / det;

    return {x, y};
  }

  private getLineSettings(points: EnvelopePoint[], color: string, width: number, type?: string) {
    let data = points.concat(points[0]).map(p => [p.x, p.y])
    return {
      type: 'line',
      data: data,
      showSymbol: false,
      lineStyle: {
        type: type ? type : 'solid',
        color: color,
        width: width
      }
    }
  }

  private getPointSettings(data: EnvelopePropertyEntry[]) {
    return {
      type: 'scatter',
      data: data.map(p => [p.x.value, p.y.value]),
      lineStyle: {
        type: 'dashed',
        color: 'green',
        width: 1
      }
    }
  }

  private setAxisValues(data: EnvelopePoint[], siUnit: SiUnit) {
    const xPoints = data.map(point => point.x)
    const yPoints = data.map(point => point.y)

    // @ts-ignore
    let xAxis: XAXisOption = this.chartConfig.xAxis
    xAxis.max = Math.round(Math.max(...xPoints) + 10)
    xAxis.min = Math.round(Math.min(...xPoints) - 10)

    // @ts-ignore
    let yAxis: XAXisOption = this.chartConfig.yAxis
    yAxis.max = Math.round(Math.max(...yPoints) + 10)
    yAxis.min = Math.round(Math.min(...yPoints) - 10)
    xAxis.name += siUnit === SiUnit.CELSIUS ? '[°C]' : '[bar]'
    yAxis.name += siUnit === SiUnit.CELSIUS ? '[°C]' : '[bar]'
  }
}
