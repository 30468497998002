import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {SystemService} from "../../../../system/model/system.service";
import {System, SystemInfo} from "../../../../system/model/system-api";
import {Page} from "../../../../utils/page";
import {PageEvent} from "@angular/material/paginator";
import {Subscription} from "rxjs";
import {OperationalGraphService} from "../../model/operational-graph.service";

@Component({
    selector: 'app-operational-graph-add-system',
    templateUrl: './operational-graph-add-system.component.html',
    styleUrl: './operational-graph-add-system.component.scss',
    standalone: false
})
export class OperationalGraphAddSystemComponent implements OnDestroy {

  @Input()
  set siteData(data: number) {
    this.siteId = data
    this.loading = true
    this.loadSystems(this.pageIndex, this.pageSize)
  }
  @Output() selectSystems: EventEmitter<System[]> = new EventEmitter()

  selectedSystems: System[] = []
  systems: SystemInfo[] = []
  subscription: Subscription | undefined
  loading: boolean = false
  siteId: number = 0
  pageIndex: number = 0
  pageSize: number = 5
  totalSize: number = -1

  constructor(
    private service: SystemService,
    private operationalGraphService: OperationalGraphService
  ) { }

  ngOnDestroy() {
    if (!this.subscription) return
    this.subscription.unsubscribe()
    this.subscription = undefined
  }

  cancel() {
    this.selectSystems.emit([])
  }

  select() {
    this.selectSystems.emit(this.selectedSystems)
  }

  handlePaginatorEvent(event: PageEvent) {
    this.loadSystems(event.pageIndex, event.pageSize)
  }

  onSystemSelect(system: System) {
    if (this.checkStatus(system)) {
      const idx = this.selectedSystems.indexOf(system)
      this.selectedSystems.splice(idx, 1)
      return
    }

    this.selectedSystems.push(system)
  }

  checkStatus(system: System) {
    return this.selectedSystems.find(s => s.id === system.id) !== undefined
  }

  private loadSystems(page: number, size: number) {
    this.subscription = this.service.getSystems(this.siteId, page, size).subscribe(data => this.handleData(data))
  }

  private handleData(data: Page<SystemInfo>) {
    this.systems = data.content
    this.pageIndex = data.pageable.number
    this.pageSize = data.pageable.size
    this.totalSize = data.totalSize
    this.selectedSystems = [...this.operationalGraphService.systems]
    this.loading = false
  }
}
