import { Component, Input } from '@angular/core';
import {
  AlarmDistributionTableEntry,
  EquipmentAlarmDistribution,
  EquipmentAlarmDistributionEntry
} from "../../model/operational-report-alarms-api";

@Component({
    selector: 'app-alarm-distribution',
    templateUrl: './alarm-distribution.component.html',
    styleUrl: './alarm-distribution.component.scss',
    standalone: false
})
export class AlarmDistributionComponent {

  @Input()
  set distributionData(data: EquipmentAlarmDistribution | undefined) {
    if (!data) return
    this.init(data)
  }

  days = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
  timeData = ['00-05h', '05-12h', '12-17h', '17-23h']
  distribution: EquipmentAlarmDistribution | undefined
  tableData: AlarmDistributionTableEntry[] = []

  constructor() { }

  private init(data: EquipmentAlarmDistribution) {
    const entries: Map<string, EquipmentAlarmDistributionEntry[]> = new Map(Object.entries(data.entries))
    this.distribution = new EquipmentAlarmDistribution(entries)
    this.tableData = []
    this.populateTable()
  }

  private formatTime(start: string, end: string) {
    return start.slice(0,2) + '-' + end.slice(0,2) + 'h'
  }

  private formatDay(value: string) {
    return value.slice(0, 2).toLowerCase()
  }

  private populateTable() {
    this.timeData.forEach(t => {
      const ten = new AlarmDistributionTableEntry(t, [])
      this.days.forEach(v => ten.alarms.push(new Array(0)))
      this.tableData.push(ten)
    })
    this.mapData()
  }

  private mapData() {
    if (!this.distribution) return

    this.distribution.entries.forEach((v, k) => {
      v.forEach(e => {
        const time = this.formatTime(e.start, e.end)
        const day = this.formatDay(k)
        const timeIdx = this.tableData.findIndex(d => d.time === time)
        const dayIdx = this.days.findIndex(d => d === day)
        if (timeIdx === -1 || dayIdx === -1) return
        this.tableData[timeIdx].alarms[dayIdx].push(...e.alarms)
      })
    })
  }
}
