import { Component, Input } from '@angular/core';
import { MeanSeriesReport } from "../../model/operational-report-activity-api";

@Component({
    selector: 'app-activity-meantime',
    templateUrl: './activity-meantime.component.html',
    styleUrl: './activity-meantime.component.scss',
    standalone: false
})
export class ActivityMeantimeComponent {

  @Input()
  set data(data: MeanSeriesReport | undefined) {
    if (!data) return
    this.report = data
  }

  report: MeanSeriesReport | undefined
}
