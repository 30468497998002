import {Component, Input} from '@angular/core';
import {
  EquipmentRuntimeCycleEntry,
  EquipmentStartStopGraph
} from "../../model/operational-report-starts-api";
import {SeriesOption} from "echarts";
import {TranslateService} from "@ngx-translate/core";
import {DateTime} from "luxon";

@Component({
    selector: 'app-starts-graph',
    templateUrl: './starts-graph.component.html',
    styleUrl: './starts-graph.component.scss',
    standalone: false
})
export class StartsGraphComponent {

  graph: EquipmentStartStopGraph | undefined
  series: SeriesOption[] = []

  constructor(
    private translate: TranslateService
  ) {
  }

  @Input()
  set graphData(data: EquipmentStartStopGraph | undefined) {
    this.graph = data
    this.initSeries()
  }

  private initSeries() {

    const series = this.defineGraphSeries()
    const starts = this.defineScatterSeries('start')
    const stops = this.defineScatterSeries('stop')

    this.series = [starts, stops, series]
  }

  private defineGraphSeries(): SeriesOption {
    const entries = this.createEntries()

    return {
      type: 'graph',
      layout: 'force',
      coordinateSystem: 'cartesian2d',
      animation: false,
      name: 'startstop',
      data: entries ? entries.data : [],
      edges: entries ? entries.edges : []
    }
  }

  private defineScatterSeries(event: string): SeriesOption {
    const isStart = event === 'start'
    const color = isStart ? '#3aaa35' : 'black'
    const name = isStart ? this.translate.instant('start') : this.translate.instant('stop')

    return {
      type: 'scatter',
      data: [],
      name: name,
      itemStyle: {
        color: color
      }
    }
  }

  private createDataPoint(entry: EquipmentRuntimeCycleEntry, id: string, timestamp: string) {
    return {
      fixed: true,
      value: [
        entry.properties[0].value,
        entry.properties[1].value,
        timestamp
      ],
      symbolSize: 10,
      itemStyle: {
        color: entry.event.code === 4 ? '#3aaa35' : 'black'
      },
      name: (entry.event.code === 4) ? this.translate.instant('start') : this.translate.instant('stop'),
      id: id,
      label: {
        show: false
      }
    }
  }

  private createEntries() {
    if (!this.graph || !this.graph.cycle.length) return

    const starts = this.graph.cycle[0]
    const stops = this.graph.cycle[1]
    const data: any[] = []
    const edges: any[] = []

    starts.entry.forEach((start, idx) => {
      const matchedStop = stops.entry.find(stop => {
        return DateTime.fromISO(stop.event.timestamp) >= DateTime.fromISO(start.event.timestamp)

      })

      if (!matchedStop) return

      const startPointId = start.event.timestamp + Math.random()
      const stopPointId = matchedStop.event.timestamp + Math.random()

      const startPoint = this.createDataPoint(start, startPointId, start.event.timestamp)
      const stopPoint = this.createDataPoint(matchedStop, stopPointId, matchedStop.event.timestamp)
      const edge = this.createEdge(startPointId, stopPointId)

      data.push(startPoint, stopPoint)
      edges.push(edge)
    })

    return {
      data: data,
      edges: edges
    }
  }

  private createEdge(source: string, target: string) {
    return {
      source: source,
      target: target
    }
  }
}
