import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Observable, startWith } from "rxjs";
import { PropertyDefinition } from "../../../../../thing-template/model/thing-template-api";
import { map } from "rxjs/operators";
import { OperationalReportService } from "../../../model/operational-report.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-meantime-dialog',
    templateUrl: './meantime-dialog.component.html',
    styleUrl: './meantime-dialog.component.scss',
    standalone: false
})
export class MeantimeDialogComponent {
  propertyControl = new FormControl('');
  filteredOptions: Observable<PropertyDefinition[]>

  constructor(
    public service: OperationalReportService,
    private dialogRef: MatDialogRef<MeantimeDialogComponent>,
  ) {
    this.filteredOptions = this.propertyControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value)
      }),
    )
  }

  private _filter(name: string | null): PropertyDefinition[] {
    if (!name) return this.service.getPropertyDefinitions()
    const filterValue = name.toLowerCase()
    return this.service.getPropertyDefinitions().filter(option => {
      return option.name.toLowerCase().includes(filterValue) || this.getPropertyName(option.name).toLowerCase().includes(filterValue)
    })
  }

  getPropertyName(name: string) {
    return this.service.translatePropertyName(name)
  }

  displayDefinition(name: string) {
    if (!name) return ''
    return this.service.translatePropertyName(name)
  }

  select() {
    this.dialogRef.close(this.propertyControl.value)
  }

  cancel() {
    this.dialogRef.close()
  }
}
