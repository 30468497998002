import {Component, Input} from '@angular/core';
import {BarChartEntry} from "./model/bar-chart.api";
import {XAXisOption, YAXisOption} from "echarts/types/dist/shared";
import {EChartsCoreOption} from "echarts/core";
import {SeriesOption} from "echarts";

@Component({
  selector: 'app-operational-bar-chart',
  templateUrl: './operational-bar-chart.component.html',
  styleUrl: './operational-bar-chart.component.scss',
  standalone: false
})
export class OperationalBarChartComponent {

  entry: BarChartEntry | undefined
  chartOptions: EChartsCoreOption = {}

  constructor() {
  }

  @Input()
  set entryData(data: BarChartEntry | undefined) {
    this.entry = data
    this.initChart()
  }

  private initChart() {
    if (!this.entry) return

    this.chartOptions = {
      grid: {containLabel: true},
      xAxis: this.initXAxis(),
      yAxis: this.initYAxis(),
      series: this.initSeries()
    }
  }

  private initXAxis() {
    const xAxis: XAXisOption = {
      type: 'value',
      axisLabel: {
        formatter: value => `${value}%`

      }
    }

    return xAxis
  }

  private initYAxis() {
    const yAxis: YAXisOption = {
      type: 'category',
      data: this.entry ? this.entry.yAxisData : [],
      axisLabel: {
        formatter: value => this.entry ? this.entry.yAxisFormatter(value) : `${value}`
      }
    }

    return yAxis
  }

  private initSeries() {
    const series: SeriesOption = {
      type: 'bar',
      data: this.entry ? this.entry.seriesData : [],
      itemStyle: {
        color: '#3aaa35'
      },
      label: {
        show: true,
        position: 'right',
        formatter: '{c}%'
      }
    }

    return series
  }
}
