import { Component } from '@angular/core';
import { EquipmentService } from "../model/equipment.service";
import { ActivatedRoute } from "@angular/router";
import { Equipment, EquipmentInfo } from "../model/equipment-api";
import { Product } from "../../portal/product/model/product-api";
import { ProductService } from "../../portal/product/model/product.service";

@Component({
    selector: 'app-equipment-details-information',
    templateUrl: './equipment-details-information.component.html',
    styleUrls: ['./equipment-details-information.component.scss'],
    standalone: false
})
export class EquipmentDetailsInformationComponent {

  equipment: EquipmentInfo | undefined
  product: Product | undefined


  constructor(
    public service: EquipmentService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.paramMap.subscribe(paramsMap => {
      const equipmentId = Number(paramsMap.get('id'))
      if (!equipmentId) return

      this.service.getInfo(equipmentId).subscribe(equipment => {
        this.handleEquipment(equipment)
      });
    })

  }

  private handleEquipment(equipment: EquipmentInfo) {
    if (!equipment) return
    this.equipment = equipment
    this.getData(equipment.equipment);
  }


  private getData(equipment: Equipment) {
    this.productService.getProductForEquipment(equipment.productId, equipment.id).subscribe(p => {
      this.handleProduct(p)
    })
  }

  private handleProduct(p: Product) {
    if (p && p.attributes.general) {
      p.attributes.general.maxOperatingCurrent = new Map(Object.entries(p.attributes.general.maxOperatingCurrent))
      p.attributes.general.displacement = new Map(Object.entries(p.attributes.general.displacement))
      p.attributes.general.oilType = new Map(Object.entries(p.attributes.general.oilType))
    }
    this.product = p
  }

}
