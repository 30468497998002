import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Equipment } from "../model/equipment-api";
import { EquipmentService } from "../model/equipment.service";

@Component({
    selector: 'app-delete-equipment-dialog',
    templateUrl: './delete-equipment-dialog.component.html',
    styleUrls: ['./delete-equipment-dialog.component.scss'],
    standalone: false
})
export class DeleteEquipmentDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Equipment,
    private dialogRef: MatDialogRef<DeleteEquipmentDialogComponent>,
    private service: EquipmentService
  ) {
  }


  submit() {
    if (!this.data.id) return

    this.service.deleteEquipment(this.data.id).subscribe(() => {
      this.dialogRef.close(true)
    })
  }
}
