import { Component, Input } from '@angular/core';
import { Equipment } from "../model/equipment-api";

@Component({
    selector: 'app-equipment-image',
    templateUrl: './equipment-image.component.html',
    styleUrls: ['./equipment-image.component.scss'],
    standalone: false
})
export class EquipmentImageComponent {

  @Input()
  set data(data: Equipment | undefined) {
    this.equipment = data
    if (this.equipment) {
      this.image = 'assets/img/comp.png'
    }
  }

  equipment: Equipment | undefined

  image: string = ''

}
