import {Component, Input} from '@angular/core';
import {
  EquipmentLoadReport,
  EquipmentLoadReportRequest
} from "../model/operational-report-load-api";
import {MatDialog} from "@angular/material/dialog";
import {
  LoadPropertySelectorComponent
} from "./load-property-selector/load-property-selector.component";
import {OperationalReportService} from "../model/operational-report.service";
import {ReportService} from "../model/report.service";
import {BarChartEntry} from "../operational-bar-chart/model/bar-chart.api";
import {DateTime} from "luxon";

@Component({
    selector: 'app-operational-report-equipment-load',
    templateUrl: './operational-report-equipment-load.component.html',
    styleUrl: './operational-report-equipment-load.component.scss',
    standalone: false
})
export class OperationalReportEquipmentLoadComponent {

  load: EquipmentLoadReport | undefined
  entry: BarChartEntry | undefined
  isCapacity: boolean = true

  constructor(
    private dialog: MatDialog,
    private service: ReportService,
    private operationalReportService: OperationalReportService
  ) {
  }

  @Input()
  set loadData(data: EquipmentLoadReport | undefined) {
    this.load = data
    this.init()
  }

  openSelectorDialog() {
    const dialogRef = this.dialog.open(LoadPropertySelectorComponent, {
      width: '30%',
      disableClose: true
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return
      const equipmentId = this.operationalReportService.equipmentId
      const request = this.createLoadReportRequest()

      if (!equipmentId) return

      this.service.getEquipmentLoadReport(equipmentId, request).subscribe(data => this.handleData(data))
    })
  }

  private init() {
    if (!this.load || !this.load.series.length) return

    const sorted = this.load.series[0].entries.sort((a, b) => b.interval.start - a.interval.start)
    const series = sorted.map(d => Number(d.percentage.toFixed(2)))
    const yAxisData = this.isCapacity ? this.getCapacityValues() : this.getFrequencyValues()
    const unit = this.isCapacity ? '%' : 'Hz'
    const formatter = (value: string) => `${value} ${unit}`

    this.entry = new BarChartEntry(yAxisData, formatter, series)
  }

  private handleData(data: EquipmentLoadReport) {
    if (!data) return
    this.load = data
    this.isCapacity = data.series.length ? data.series[0].property.name === 'BDN.Capacity' : true
    this.init()
  }

  private createLoadReportRequest() {
    const rangeValue = this.operationalReportService.range.value
    const from = rangeValue.start ? DateTime.fromISO(rangeValue.start).startOf('day').toISO() ?? '' : '';
    const to = rangeValue.end ? DateTime.fromISO(rangeValue.end).endOf('day').toISO() ?? '' : '';

    const properties = this.operationalReportService.getLoadProperty()

    return new EquipmentLoadReportRequest(from, to, properties)
  }

  private getCapacityValues() {
    return [
      '100',
      '75 - 100',
      '50 - 75',
      '25 - 50',
      '10 - 25',
      '0 - 10'
    ]
  }

  private getFrequencyValues() {
    return [
      '> 90',
      '81 - 90',
      '71 - 80',
      '61 - 70',
      '51 - 60',
      '41 - 50',
      '31 - 40',
      '20 - 30',
      '< 20'
    ]
  }
}
