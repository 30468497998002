import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyCategory, PropertyDefinition, ThingTemplate } from "../../thing-template/model/thing-template-api";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { NgForOf, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../../material/material.module";
import { ReactiveFormsModule } from "@angular/forms";
import { Subject } from "rxjs";
import { ThingTemplateService } from "../../thing-template/model/thing-template.service";
import { Equipment } from "../../equipment/model/equipment-api";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: 'app-property-category-selector',
    templateUrl: './property-category-selector.component.html',
    styleUrl: './property-category-selector.component.scss',
    standalone: false
})
export class PropertyCategorySelectorComponent {


  @Input()
  equipment: Equipment | undefined


  @Output()
  event = new EventEmitter<Set<string>>()

  data: PropertyCategory[] = []
  selectedCategories = new Set<string>(["General Capacity", "General Temperature"])
  selectedProperties = new Set<string>(["BDN.Capacity", "BDN.CondTemp", "BDN.EvapTemp", "BDN.OilDiscGasTemp"])
  private subject: Subject<undefined> = new Subject();

  constructor(
    private thingTemplateService: ThingTemplateService,
  ) {
  }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(1500)
    ).subscribe(_ => {
      this.event.emit(this.selectedProperties)
    })
    this.loadTemplate()
  }

  selectProperty(checked: boolean, property: PropertyDefinition) {
    if (checked) {
      this.selectedProperties.add(property.name)
      this.data.forEach(category => {
        if (category.properties.includes(property)) this.selectedCategories.add(category.name)
      })
    } else {
      this.selectedProperties.delete(property.name)
      const category =
      this.data.find(category => category.properties.includes(property))
      const propertiesSelected = category?.properties.find(property => this.selectedProperties.has(property.name))
      if(!propertiesSelected) this.selectedCategories.delete(category?.name || '')
    }
    this.subject.next(undefined)
  }

  contains(property: PropertyDefinition) {
    return this.selectedProperties.has(property.name)
  }

  private loadTemplate() {
    if (!this.equipment) return
    this.thingTemplateService.getEquipmentTemplate(this.equipment.id).subscribe(data => this.handleThingTemplate(data))
  }

  private handleThingTemplate(data: ThingTemplate) {
    this.data = data.categories
  }

  selectCategory(category: PropertyCategory) {
    const checked = this.selectedCategories.has(category.name)
    if (checked) {
      this.selectedCategories.delete(category.name)
      category.properties.forEach(property => {
        this.selectProperty(false, property)
      })
    } else {
      this.selectedCategories.add(category.name)
      category.properties.forEach(property => {
        this.selectProperty(true, property)
      })
    }
  }
}
