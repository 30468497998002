import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Equipment } from "../../equipment/model/equipment-api";
import { PropertyCategory, PropertyDefinition, ThingTemplate } from "../../thing-template/model/thing-template-api";
import { ThingTemplateService } from "../../thing-template/model/thing-template.service";

@Component({
    selector: 'app-property-category-selector-dialog',
    templateUrl: './property-category-selector-dialog.component.html',
    styleUrl: './property-category-selector-dialog.component.scss',
    standalone: false
})
export class PropertyCategorySelectorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { equipment: Equipment, properties: Set<string> },
    private thingTemplateService: ThingTemplateService,
  ) {
  }

  categories: PropertyCategory[] = []
  selectedCategories = new Set<string>()
  selectedProperties = new Set<string>()


  ngOnInit() {
    this.selectedProperties = new Set(this.data.properties)
    this.loadTemplate()
  }

  selectProperty(checked: boolean, property: PropertyDefinition) {
    if (checked) {
      this.selectedProperties.add(property.name)
      this.categories.forEach(category => {
        if (category.properties.includes(property)) this.selectedCategories.add(category.name)
      })
    } else {
      this.selectedProperties.delete(property.name)
      const category =
        this.categories.find(category => category.properties.includes(property))
      const propertiesSelected = category?.properties.find(property => this.selectedProperties.has(property.name))
      if (!propertiesSelected) this.selectedCategories.delete(category?.name || '')
    }
  }

  contains(property: PropertyDefinition) {
    return this.selectedProperties.has(property.name)
  }

  private loadTemplate() {
    if (!this.data.equipment) return
    this.thingTemplateService.getEquipmentTemplate(this.data.equipment.id).subscribe(data => this.handleThingTemplate(data))
  }

  private handleThingTemplate(data: ThingTemplate) {
    this.categories = data.categories
    this.selectedProperties.forEach(property => this.addCategoryForProperty(property))
  }

  addCategoryForProperty(propertyName: string) {
    const category = this.categories.find(category => category.properties.find(p => p.name == propertyName))
    this.selectedCategories.add(category?.name || '')
  }

  selectCategory(category: PropertyCategory) {
    const checked = this.selectedCategories.has(category.name)
    if (checked) {
      this.selectedCategories.delete(category.name)
      category.properties.forEach(property => {
        this.selectProperty(false, property)
      })
    } else {
      this.selectedCategories.add(category.name)
      category.properties.forEach(property => {
        this.selectProperty(true, property)
      })
    }
  }
}
