import { Component, Input } from '@angular/core';
import { Interval } from "../../model/operational-report-api";
import {
  EquipmentRuntimeDistributionReport,
  RuntimeDistributionEntry
} from "../../model/operational-report-activity-api";
import { BarChartEntry } from "../../operational-bar-chart/model/bar-chart.api";

@Component({
    selector: 'app-activity-runtime',
    templateUrl: './activity-runtime.component.html',
    styleUrl: './activity-runtime.component.scss',
    standalone: false
})
export class ActivityRuntimeComponent {

  @Input()
  set data(data: EquipmentRuntimeDistributionReport | undefined) {
    if (!data) return
    this.report = data
    this.process(data)
  }

  report: EquipmentRuntimeDistributionReport | undefined
  entry: BarChartEntry | undefined

  private process(data: EquipmentRuntimeDistributionReport) {
    if (!data) return
    const distribution= [65, 60, 45, 30, 15, 5]
    const sorted= distribution.map(range => {
      let entry = data.entry.find(e => {
        return e.interval.end === range
      })
      if (!entry) return this.createDefaultEntry(range)
      return entry
    })

    const series = sorted.map(d => Number(d.percentage.toFixed(2)))
    const yAxisData = this.getYAxisValues()
    const formatter = (value: string) => `${value} min`

    this.entry = new BarChartEntry(yAxisData, formatter, series)
  }

    getYAxisValues() {
    return [
      '> 60',
      '45 - 60',
      '30 - 45',
      '15 - 30',
      '5 - 15',
      '< 5',
    ]
  }

  createDefaultEntry(max: number) {
    let min
    switch (max) {
      case 5: {
        min = 0
        break
      }
      case 15: {
        min = 5
        break
      }
      case 65: {
        min = 60
        break
      }
      default: min = max - 15
    }
    return new RuntimeDistributionEntry(new Interval(min, max), 0, 0)
  }

  getMeanValue() {
    let mean = this.report?.meanValue
    return (mean && !isNaN(mean)) ? mean.toFixed() : '0'
  }
}
