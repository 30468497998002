import { Component } from '@angular/core';
import { ParameterGroup } from "../../site/model/parameter-api";
import { ParameterService } from "../../site/model/parameter.service";
import { ActivatedRoute } from "@angular/router";
import { EquipmentService } from "../model/equipment.service";

@Component({
    selector: 'app-equipment-details-module-settings',
    templateUrl: './equipment-details-module-settings.component.html',
    styleUrls: ['./equipment-details-module-settings.component.scss'],
    standalone: false
})
export class EquipmentDetailsModuleSettingsComponent {

  parameterGroups: ParameterGroup[] = []
  reloading: boolean = false

  constructor(
    private service: EquipmentService,
    private activatedRoute: ActivatedRoute,
    public parameterService: ParameterService
  ) {
    this.activatedRoute.paramMap.subscribe(paramsMap => {
      const equipmentId = Number(paramsMap.get('id'))
      if (!equipmentId) return
      this.service.getInfo(equipmentId).subscribe(equipment => {
        if (equipment.equipment.thingId) {
          if (this.reloading) return
          this.reloading = true
          this.getParameter(equipment.equipment.thingId)
        }
      })
    })
  }

  private getParameter(thingId: number) {
    this.parameterService.getTranslatedParameterViews(thingId, 'en').subscribe(data => {
      let groups = data.map(item => item.groups || [])
      this.parameterGroups = groups.flat()
      this.reloading = false
    })
  }
}
